import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'reset.css/reset.css'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import server from '@/utils/request'
import vaudio from '@/utils/vaudio'
import formate from "@/utils/common"
import myapp from '@/utils/myapp'

Vue.use(ElementUI);
Vue.config.productionTip = false

Vue.prototype.$server = server
Vue.prototype.$local = server.local
Vue.prototype.$audio = vaudio
Vue.prototype.$formate = formate
Vue.prototype.$myapp = myapp


//使用钩子函数对路由进行权限跳转
router.beforeEach((to, from, next) => {
	document.title = `${to.meta.title} | 视觉训练系统`;
	const meta = document.createElement('meta');
	meta.name = 'viewport';
	meta.content = 'width=device-width; initial-scale=1.0; maximum-scale=1.0; user-scalable=0;';
	document.head.appendChild(meta);
	
	const role = localStorage.getItem('__htsclient_userInfo__');
	let expireTime = localStorage.getItem('__htsclient_expireTime__');
	let now = new Date().getTime();
	let login = role && (expireTime > now);

	if (!login && to.path !== '/login' && to.path !== '/') {
		next('/');
	} else {
		// 简单的判断IE10及以下不进入富文本编辑器，该组件不兼容
		if (navigator.userAgent.indexOf('MSIE') > -1 && to.path === '/editor') {
			Vue.prototype.$alert('vue-quill-editor组件不兼容IE10及以下浏览器，请使用更高版本的浏览器查看', '浏览器不兼容通知', {
				confirmButtonText: '确定'
			});
		} else {
			next();
		}
	}
});

// 禁用鼠标右键点击
document.addEventListener('contextmenu', function(event) {
  event.preventDefault();
});

// 阻止文本选择
document.body.style.webkitUserSelect = 'none'; // Safari
document.body.style.MozUserSelect = 'none';    // Firefox
document.body.style.msUserSelect = 'none';     // Internet Explorer/Edge
document.body.style.userSelect = 'none';       // Non-prefixed version

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')