import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userRole: localStorage.getItem('userRole') || null,
  },
  getters: {
    userRole: state => state.userRole
  },
  mutations: {
    setUserRole(state, role) {
      state.userRole = role;
      localStorage.setItem('userRole', role);
    },
    clearUserRole(state) {
      state.userRole = null;
      localStorage.removeItem('userRole');
    }
  },
  actions: {
    login({commit}, role) {
      commit('setUserRole', role);
    },
    logout({commit}) {
      commit('setUserRole');
    }
  },
  modules: {}
})
